import React, {useEffect, useState} from 'react'
import {ResumeLayoutAndStylingControlsProps, StyleObject} from './ResumeFormattingModel'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {BlockPicker, TwitterPicker} from 'react-color'

const resumes = [
  {
    id: 'forum_new',
    img: toAbsoluteUrl('/media/resume/foremresume.png'),
  },
  {
    id: 'thirdeye',
    img: toAbsoluteUrl('/media/resume/thirdeyeresume.png'),
  },
  {
    id: 'vpinternational',
    img: toAbsoluteUrl('/media/resume/vpresume.png'),
  },
]

const fontSizes = [
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
]
const fontStyles = [
  'Oswald',
  'Arial',
  'Lexend',
  'Lora',
  'Times New Roman',
  'Roboto',
  'Calibri',
  'EB Garamond',
  'Montesrrat',
  'Lato',
]

const ResumeLayoutAndStylingControls = ({setTemplate}: ResumeLayoutAndStylingControlsProps) => {
  const [formatType, setFormatType] = useState<string>('forum_new')
  const [styleData, setStyleData] = useState<StyleObject>({
    headingFontStyle: 'Lato', // Initial value for headingFontStyle
    headingFontSize: '14', // Initial value for headingFontSize
    contentFontStyle: 'Roboto', // Initial value for contentFontStyle
    contentFontSize: '11', // Initial value for contentFontSize
  })
  const [primaryColor, setPrimaryColor] = useState<string>()

  useEffect(() => {
    setTemplate(formatType)
  }, [formatType])

  return (
    <div
      className={`card card-xl-stretch mb-5 mb-xl-8 p-5`}
      style={{height: '70vh', overflowY: 'scroll'}}
    >
      <ul className='nav nav-pills nav-justified'>
        <li className='nav-item' role='presentation'>
          <button
            className='nav-link active btn me-3'
            id='pills-home-tab'
            data-bs-toggle='pill'
            data-bs-target='#pills-home'
            type='button'
            role='tab'
            aria-controls='pills-home'
            aria-selected='true'
          >
            <KTIcon iconName='abstract-7' className='fs-1' />
            Templates
          </button>
        </li>
        <li className='nav-item' role='presentation'>
          <button
            className='nav-link btn'
            id='pills-profile-tab'
            data-bs-toggle='pill'
            data-bs-target='#pills-profile'
            type='button'
            role='tab'
            aria-controls='pills-profile'
            aria-selected='false'
          >
            <KTIcon iconName='text-number' className='fs-1' />
            Styling
          </button>
        </li>
      </ul>
      <div className='tab-content p-2' id='pills-tabContent'>
        <div
          className='tab-pane fade show active'
          id='pills-home'
          role='tabpanel'
          aria-labelledby='pills-home-tab'
          tabIndex={0}
        >
          <div className='row d-flex align-items-center mb-5'>
            <div className='fs-4 fw-bold mb-5'>Color</div>
            <TwitterPicker
              width='100%'
              onChangeComplete={(color) => {
                setPrimaryColor(color.hex)
              }}
            />
          </div>
          <div className='row d-flex align-items-center'>
            <div className='fs-4 fw-bold mb-5'>Templates</div>
            {resumes.map((resume) => (
              <div className='col-6'>
                <div className='form-check my-2' style={{width: 'fit-content'}}>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='flexRadioDefault'
                    id={resume.id}
                    value={formatType}
                    style={{
                      zIndex: '2',
                      position: 'relative',
                      backgroundColor: '#1a1a1a',
                    }}
                    onChange={(e) => {
                      setFormatType(resume.id)
                    }}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault2'
                    style={{
                      position: 'relative',
                      left: '-30px',
                      top: '-10px',
                      zIndex: '1',
                    }}
                  >
                    <img src={resume.img} height={200} />
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className='tab-pane fade p-2'
          id='pills-profile'
          role='tabpanel'
          aria-labelledby='pills-profile-tab'
          tabIndex={1}
        >
          <div className='row'>
            <div className='col-12'>
              <div className='row mb-4'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className=''>Heading Font Style </span>
                </label>

                <div className='col-lg-12 fv-row'>
                  <select
                    className='form-select form-select-solid'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    value={styleData?.headingFontStyle}
                    onChange={(e) =>
                      setStyleData((prevStyleData) => ({
                        ...prevStyleData,
                        headingFontStyle: e.target.value,
                      }))
                    }
                  >
                    <option value=''>---Select Heading Font Style---</option>
                    {fontStyles.map((fontStyle) => (
                      <option value={fontStyle}>{fontStyle}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div className='row mb-4'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className=''>Heading Font Size</span>
                </label>

                <div className='col-lg-12 fv-row'>
                  <select
                    className='form-select form-select-solid'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    value={styleData?.headingFontSize}
                    onChange={(e) =>
                      setStyleData((prevStyleData) => ({
                        ...prevStyleData,
                        headingFontSize: e.target.value,
                      }))
                    }
                  >
                    <option value=''>---Select Heading Font Size---</option>
                    {fontSizes.map((fontSize) => (
                      <option value={fontSize}>{fontSize}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div className='row mb-4'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className=''>Content Font Style </span>
                </label>

                <div className='col-lg-12 fv-row'>
                  <select
                    className='form-select form-select-solid'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    value={styleData?.contentFontStyle}
                    onChange={(e) =>
                      setStyleData({
                        ...styleData,
                        contentFontStyle: e.target.value,
                      })
                    }
                  >
                    <option value=''>---Select Content Font Style---</option>
                    {fontStyles.map((fontStyle) => (
                      <option value={fontStyle}>{fontStyle}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div className='row mb-4'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className=''>Content Font Size</span>
                </label>

                <div className='col-lg-12 fv-row'>
                  <select
                    className='form-select form-select-solid'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    value={styleData?.contentFontSize}
                    onChange={(e) =>
                      setStyleData({
                        ...styleData,
                        contentFontSize: e.target.value,
                      })
                    }
                  >
                    <option value=''>---Select Content Font Size---</option>
                    {fontSizes.map((fontSize) => (
                      <option value={fontSize}>{fontSize}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResumeLayoutAndStylingControls
